.btn-settings-wrapper{
    display: flex;
}
.btn-settings-wrapper .grow{
    flex-grow: 1;
}
.btn-settings-wrapper .dropdown{
    flex-grow: 1;
}
.btn-settings-wrapper .btn-settings{
    color: #fff !important;
    background: dodgerblue !important;
    border: none !important;
    box-shadow: 0px 0px 10px #cacaca9c !important;
    border-radius: 0 !important;
}