.wizard-container .progress-wrapper {
  top: 45%;
  position: absolute;
  width: 75%;
  left:15%;
}
.wizard-container .progress-wrapper .progress {
  height: 5px;
}

.wizard-container .nav-item a {
  display: inline-block;
  background-color: #e9ecef;
  color:black;
}

.wizard-container .nav-item a.checked,
.wizard-container .nav-item a.active {
  /* background-color: #007bff; */
  color: #fff;
}

.wizard-container .nav {
  position: relative;
}

.wizard-container .nav-item .rounded{
  border-radius: 5rem !important;
}