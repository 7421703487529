.react-tel-input {
  .flag-dropdown {
    cursor: pointer;
  }
  .form-control{
    height: calc(1.5em + 0.6rem);
  }
}

.was-validated {
  .react-tel-input {
    .form-control {

      &:valid,
      &:invalid {
        &:focus,
        &:active {
          box-shadow: none;
        }
        border-color: none !important;
        background-image: none !important;
      }

      &.is-valid-number {
        border-color: var(--success) !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") !important;
        &:focus,
        &:active {
          box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
        }
      }
      &.is-invalid-number {
        &:focus,
        &:active {
          box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
        }
        border-color: var(--danger) !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
      }
    }
  }
}
