.toolbarButtonWrapper {
    display: inline-block;
  }

  .toolbarButton {
    background: #fbfbfb;
    color: #888;
    font-size: 18px;
    border: 0;
    padding-top: 5px;
    vertical-align: bottom;
    height: 34px;
    
  }
  select.toolbarButton{
   font-size: 12px;
  }
  button.toolbarButton {
    width: 36px;
  }

  .toolbarButton.active{
    background: #efefef;
    color:#444;
  }
  
  .toolbarButton:hover,
  .toolbarButton:focus {
    background: #f3f3f3;
  }