.file-uploader .file-preview-container {
  width: 150px;
  height: 200px; }
  .file-uploader .file-preview-container .close-button {
    display: none;
    position: absolute;
    right: 0;
    top: 0; }
  .file-uploader .file-preview-container .card-body {
    max-height: 150px; }
  .file-uploader .file-preview-container:hover .close-button {
    display: block; }
