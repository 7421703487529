.rbt-input {
  width: 100% !important;
  box-shadow: 0 0 0 0 #00000000 !important;
  /* border-color: #00000000 !important; */
  border: none !important;
  border-bottom: 2px solid #c0bebe !important;
  transition: all 0.3s ease-in; }
  .rbt-input:focus {
    border-bottom: 3px solid var(--primary) !important; }
  .rbt-input.is-invalid {
    border-bottom: 3px solid var(--danger) !important; }

.was-validated .rbt-input:invalid {
  border-bottom: 3px solid var(--danger) !important; }

.rbt-input.is-valid, .was-validated .rbt-input:valid {
  border-bottom: 3px solid var(--success) !important; }
