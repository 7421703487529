.custom-img-container{
    position: relative;

}
.border{
    border: 7px solid transparent;
  -webkit-border-image: url("./border.png") 30 round; /* Safari 3.1-5 */
  -o-border-image: url("./border.png") 30 round; /* Opera 11-12.1 */
  border-image: url("./border.png") 30 round;
}
.custom-img-container .edit-options{
    position: absolute;
    background: url('overlay.png');
    display: none;
    width: 100%;
    height:100%;
    color: #fff;
    top:0;
    left:0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    cursor: pointer;
}

.custom-img-container .edit-options button{
    user-select: initial;
    pointer-events: initial;
}
.custom-img-container:hover >.edit-options{
    display: block;
}

.custom-img-container .edit-options .options{
    position: absolute;
    top: 6px;
    right:6px;
    width:100%;
}
